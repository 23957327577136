<template>
  <div class="row">
    <div class="flex xs12">
      <va-card :title="$t('districts.new')">
        <actions
          slot="actions"
          crud-links="districts"
          :actions="actions"
        />
        <data-form
          :district="district"
          :loading="loading"
          @submit="submit"
        ></data-form>
      </va-card>
    </div>
  </div>
</template>

<script>
const Actions = () => import(/* webpackPrefetch: true */ '@/components/extras/Actions')
const DataForm = () => import(/* webpackPrefetch: true */ './Form')

export default {
  name: 'districts-new',
  components: {
    Actions, DataForm,
  },
  data () {
    return {
      loading: false,
      actions: ['index'],
      district: {
        id: 0,
      },
    }
  },
  methods: {
    // FIXME
    routeBuilder () {
      return 'districts/'
    },
    async submit (district) {
      this.loading = true

      let response = false
      try {
        response = await this.$http.post(this.routeBuilder(), district)
      } catch (error) {
        this.loading = false
        return
      }

      this.loading = false
      this.showToast(this.$t('notifications.alerts.success'), {
        icon: 'fa-check',
        position: 'top-right',
      })
      this.$router.push({ name: 'districtsEdit', params: { id: response.data.data.id } })
    },
  },
}
</script>
